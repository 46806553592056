import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { navigate } from "gatsby";

import {
  SinglePageWrapper,
  ScrollButtonsWrapper,
  PageIndicator,
} from "./style";
import ScrollToTop from "./ScrollToTop";
import Breakpoint from "../Breakpoint";
import {
  LocationProp,
  MouseEventType,
  PageThemeType,
} from "../../../utils/types";

export interface ScrollRefMethods {
  goToPage: (newPage: number) => void;
  blockScroll: (block: boolean) => void;
  showFooterOnTop: (show: boolean) => void;
  setPageTheme: (theme?: PageThemeType) => void;
}

export type PageChange = "next" | "previous" | undefined;

interface ScrollProps extends LocationProp {
  isModalOpen: boolean;
  pageThemes: PageThemeType | PageThemeType[];
  transitionTimeoutMS?: number;
  pageChange?: PageChange;
  setPageChange?: (value?: PageChange) => void;
  children: React.ReactNode[];
  page: number;
  setPage: (value: number) => void;
}

const nextPageKeys = ["ArrowDown", "PageDown"];
const previousPageKeys = ["ArrowUp", "PageUp"];

const Scroll: React.ForwardRefRenderFunction<ScrollRefMethods, ScrollProps> = (
  {
    location,
    isModalOpen,
    pageThemes,
    transitionTimeoutMS = 600,
    pageChange,
    setPageChange,
    children,
    page,
    setPage,
  },
  ref
) => {
  const pathName = location.pathname.replace(/^\/+|\/+$/g, "");
  const [scrollStart, setScrollStart] = useState<number>(0);
  const [lastAbsoluteScrollValue, setLastAbsoluteScrollValue] = useState<
    number
  >(0);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const [scrollBlocked, setScrollBlock] = useState<boolean>(false);
  const [showFooterOnTop, setShowFooter] = useState<boolean>(false);
  const [themeOverride, setThemeOverride] = useState<PageThemeType>();
  const [theme, setTheme] = useState<PageThemeType>(
    Array.isArray(pageThemes) ? pageThemes[page - 1] : pageThemes
  );

  const isSafari =
    typeof window !== "undefined"
      ? /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      : false;
  const nOfPages = React.Children.count(children);
  const pages = React.Children.toArray(children);

  useImperativeHandle(ref, () => ({
    goToPage: (newPage: number) => {
      if (newPage >= 0 && newPage < nOfPages) {
        setPageChange && setPageChange("previous");
        !pageChange &&
          setTimeout(() => {
            setPageChange && setPageChange();
            setPage(newPage);

            if (newPage !== 0) {
              navigate(`#${check(newPage)}`);
            } else {
              navigate(".");
            }
            setScrollBlock(false);
            setShowFooter(false);
          }, transitionTimeoutMS);
      }
    },
    blockScroll: (block: boolean) => {
      setScrollBlock(block);
    },
    showFooterOnTop: (show: boolean) => {
      setShowFooter(show);
    },
    setPageTheme: (theme?: PageThemeType) => {
      setThemeOverride(theme);
    },
  }));

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      const timeNow = Date.now();
      const timeFromLastScroll = timeNow - lastScroll;
      const transitionTimeout = 2 * transitionTimeoutMS;
      const scrollValue = e.deltaY;
      const absoluteScrollValue = Math.abs(scrollValue);
      const momentumScrollMargin = 5;

      if (
        !!pageChange ||
        isModalOpen ||
        scrollBlocked ||
        timeFromLastScroll < transitionTimeout ||
        absoluteScrollValue < lastAbsoluteScrollValue ||
        (absoluteScrollValue === lastAbsoluteScrollValue &&
          absoluteScrollValue < momentumScrollMargin)
      ) {
        setLastAbsoluteScrollValue(absoluteScrollValue);
        return;
      }

      const scroll = Math.ceil(document.body.scrollTop);
      const scrollRange =
        document.body.scrollHeight - document.body.clientHeight;
      const scrollMargin =
        timeFromLastScroll < 1.5 * transitionTimeout ? 16 : 0;
      setLastAbsoluteScrollValue(absoluteScrollValue);

      if (
        (scrollRange !== 0 && scroll >= scrollRange && scrollValue > 0) ||
        (scrollRange === 0 && scrollValue > scrollMargin)
      ) {
        nextPage();
        setLastScroll(timeNow);
      } else if (
        (scrollRange !== 0 && scroll <= 0 && scrollValue < 0) ||
        (scrollRange === 0 && scrollValue < -1 * scrollMargin)
      ) {
        previousPage();
        setLastScroll(timeNow);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      const timeNow = Date.now();
      const timeFromLastScroll = timeNow - lastScroll;
      const transitionTimeout = 2 * transitionTimeoutMS;

      if (
        !!pageChange ||
        isModalOpen ||
        scrollBlocked ||
        timeFromLastScroll < transitionTimeout
      ) {
        return;
      }

      const keyDown = e.code;
      const scroll = Math.ceil(document.body.scrollTop);
      const scrollRange =
        document.body.scrollHeight - document.body.clientHeight;

      if (
        nextPageKeys.includes(keyDown) &&
        ((scrollRange !== 0 && scroll >= scrollRange) || scrollRange === 0)
      ) {
        nextPage();
        setLastScroll(timeNow);
      } else if (
        previousPageKeys.includes(keyDown) &&
        ((scrollRange !== 0 && scroll <= 0) || scrollRange === 0)
      ) {
        previousPage();
        setLastScroll(timeNow);
      }
    };

    const handleTouchStart = (e: TouchEvent) => {
      if (isModalOpen || scrollBlocked) {
        return;
      }

      setScrollStart(e.changedTouches[0].clientY);
    };

    const handleTouchScroll = (e: TouchEvent) => {
      const timeNow = Date.now();
      const timeFromLastScroll = timeNow - lastScroll;
      const transitionTimeout = 2 * transitionTimeoutMS;
      const touchMove = e.changedTouches[0].clientY - scrollStart;
      const absoluteScrollValue = Math.abs(touchMove);
      const momentumScrollMargin = 5;

      if (
        !!pageChange ||
        isModalOpen ||
        scrollBlocked ||
        timeFromLastScroll < transitionTimeout ||
        absoluteScrollValue < lastAbsoluteScrollValue ||
        (absoluteScrollValue === lastAbsoluteScrollValue &&
          absoluteScrollValue < momentumScrollMargin)
      ) {
        setLastAbsoluteScrollValue(absoluteScrollValue);
        return;
      }

      const { top: pageTop, height: pageHeight } = document
        .getElementById("page")!
        .getBoundingClientRect();
      const scroll = Math.ceil(Math.abs(pageTop));
      const pageHeightWithoutAddressBar = Math.round(
        document.getElementById("___gatsby")!.getBoundingClientRect().height
      );
      const vh = Math.max(document.body.clientHeight, window.innerHeight || 0);
      const scrollRange = Math.round(pageHeight) - vh;
      const scrollMargin =
        timeFromLastScroll < 1.5 * transitionTimeout ? 16 : 0;
      const hasNotScrollRange =
        vh - pageHeightWithoutAddressBar === scrollRange || scrollRange === 0;
      setLastAbsoluteScrollValue(absoluteScrollValue);

      if (
        (scrollRange !== 0 && scroll >= scrollRange && touchMove < 0) ||
        (hasNotScrollRange && touchMove < scrollMargin)
      ) {
        nextPage();
        setLastScroll(timeNow);
      } else if (
        (scrollRange !== 0 && scroll <= 0 && touchMove > 0) ||
        (hasNotScrollRange && touchMove > scrollMargin)
      ) {
        previousPage();
        setLastScroll(timeNow);
      }
    };

    document.body.addEventListener("scroll", handleThemeChange, {
      passive: true,
    });
    window.addEventListener("wheel", handleScroll, { passive: true });
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchScroll);

    return () => {
      document.body.removeEventListener("scroll", handleThemeChange);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchScroll);
    };
  }, [
    isModalOpen,
    pageThemes,
    scrollBlocked,
    page,
    scrollStart,
    lastScroll,
    lastAbsoluteScrollValue,
  ]);

  useEffect(() => {
    if (Array.isArray(pageThemes[page - 1])) {
      handleThemeChange();
    } else {
      setTheme(Array.isArray(pageThemes) ? pageThemes[page] : pageThemes);
    }
  }, [page]);

  const handleThemeChange = () => {
    if (Array.isArray(pageThemes[page - 1])) {
      const scroll = Math.ceil(document.body.scrollTop);
      const windowHeight = document.body.clientHeight;
      const themeIndex = Math.floor((scroll + windowHeight / 2) / windowHeight);
      const newTheme = pageThemes[page - 1][themeIndex] as PageThemeType;

      setTheme(newTheme);
    }
  };

  const homeObj = {
    1: "who-we-are",
    2: "technologies",
    3: "history",
    4: "our-clients",
    5: "csr",
    6: "acknowledgement",
    7: "eko",
    8: "oke",
  };

  const rndObj = {
    1: "team",
    2: "civileo",
    3: "tv-stream-ai",
  };

  const careerObj = {
    1: "about-us",
    2: "recruitment",
    3: "internships",
    4: "current-job-offers",
    5: "what-we-offer",
    6: "join-us",
  };

  const check = page => {
    let obj;

    if (pathName === "") {
      obj = homeObj;
    }
    if (pathName === "rnd") {
      obj = rndObj;
    }
    if (pathName === "career") {
      obj = careerObj;
    }

    return obj[page];
  };

  const previousPage = () => {
    if (isModalOpen || scrollBlocked) {
      return;
    }

    const newPage = page - 1;

    if (newPage >= 0) {
      setPageChange && setPageChange("previous");
      !pageChange &&
        setTimeout(() => {
          setPageChange && setPageChange();
          setPage(newPage);
          if (newPage !== 0) {
            navigate(`#${check(newPage)}`, { replace: true });
          } else {
            navigate(".", { replace: true });
          }

          const pageHeight = isSafari
            ? document.getElementById("page")!.getBoundingClientRect().height
            : document.body.scrollHeight;
          const scrollRange = pageHeight - document.body.clientHeight;
          document.body.scroll(0, scrollRange);
        }, transitionTimeoutMS);
    }
  };

  const nextPage = () => {
    if (isModalOpen || scrollBlocked) {
      return;
    }

    const newPage = page + 1;

    if (newPage < nOfPages) {
      setPageChange && setPageChange("next");
      !pageChange &&
        setTimeout(() => {
          setPageChange && setPageChange();
          setPage(newPage);
          navigate(`#${check(newPage)}`, { replace: true });
          document.body.scroll(0, 0);
        }, transitionTimeoutMS);
    }
  };

  const scrollToFirstPage = () => {
    if (isModalOpen) {
      return;
    }

    setPageChange && setPageChange("previous");
    setTimeout(() => {
      setPageChange && setPageChange();
      setPage(0);
      navigate(".");
    }, transitionTimeoutMS);
  };

  const goToPage = (e: MouseEventType, newPage: number) => {
    e.preventDefault();

    setPageChange && setPageChange("previous");
    !pageChange &&
      setTimeout(() => {
        setPageChange && setPageChange();
        setPage(newPage);
        if (newPage !== 0) {
          navigate(`#${check(newPage)}`);
        } else {
          navigate(".");
        }
        document.body.scroll(0, 0);
      }, transitionTimeoutMS);
  };

  return (
    <SinglePageWrapper id="page">
      {pages[page]}
      <Breakpoint device={["tablet", "tabletLandscape", "computer"]}>
        {!isModalOpen && (
          <>
            <ScrollButtonsWrapper>
              {pages.map((_, index) => (
                <PageIndicator
                  onClick={(e: MouseEventType) => goToPage(e, index)}
                  selected={index === page ? 1 : 0}
                  pageTheme={
                    themeOverride
                      ? themeOverride
                      : Array.isArray(theme)
                      ? theme[0]
                      : theme
                  }
                  className="pageIndicator"
                  key={index}
                />
              ))}
            </ScrollButtonsWrapper>
            {page === nOfPages - 1 && (
              <ScrollToTop
                scrollToFirstPage={scrollToFirstPage}
                pageChange={pageChange}
              />
            )}
          </>
        )}
      </Breakpoint>
    </SinglePageWrapper>
  );
};

export default forwardRef(Scroll);
